import request from '@/helper/request'
export function getSelfMediaList() {
  return request({
    url: 'api/self-medium-list',
    method: 'get'
  })
}
export function getRecruitList(data) {
  return request({
    url: 'api/recruit-list',
    method: 'post',
    data: data
  })
}
export function getRecruitDetail(data) {
  return request({
    url: 'api/recruit-detail',
    method: 'post',
    data: data
  })
}

export function getOrgans(payload) {
  return request({
    url: 'api/organs',
    method: 'get',
    params: payload
  })
}
export function getRegions(payload) {
  return request({
    url: 'api/organ-region-list',
    method: 'get',
    params: payload
  })
}
export function getProvince(data) {
  return request({
    url: 'api/distinct-province-organ-list',
    method: 'post',
    data: data
  })
}
export function getCities(payload) {
  return request({
    url: 'api/recruit-city-list',
    method: 'get',
    params: payload
  })
}
export function getDepartments(data) {
  return request({
    url: 'api/recruit-department-list',
    method: 'post',
    data: data
  })
}
