<template>
  <div :class="[title.size ? title.size : '', 'title']">
    <div class="title-name">{{title.name}}</div>
    <div class="en hightlight"><span>/</span> {{title.en_1}}</div>
    <div class="en">{{title.en_2}}</div>
    <div class="en">{{title.en_3 || ''}}</div>
    <div class="en">{{title.en_4 || ''}}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  white-space: nowrap;
  &.big {
    .en {
      letter-spacing: 0.18rem;
    }
  }
  display: flex;
  height: 1.06rem;
  align-items: center;

  border-bottom: 1px solid #dcdcdc;
  .title-name {
    font-size: 0.3rem;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    &::after {
      width: 1.24rem;
      height: 0.04rem;
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: -1px;
      background-color: #fe6917;
    }
  }
  .en {
    font-size: 0.16rem;
    color: #dcdcdc;
    margin-left: 0.4rem;
    letter-spacing: 0.22rem;
    &.hightlight {
      color: #fe6917;
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .title {
    display: none;
    .title-name {
      min-width: 66px;
      flex-shrink: 0;
      font-size: 16px;
    }
    .en {
      word-break: break-all;
      white-space: nowrap;
      //text-overflow: ellipsis;
      overflow: hidden;
    }
    .en:last-child {
      display: none;
    }
  }
}
</style>