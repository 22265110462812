<template>
  <div class="page-recruit">
    <item-title :title="{name: '招贤纳士', en_1: 'JOIN', en_2: 'US'}" />
    <!-- <div class="filter-container clearfix">
      <search v-if="isMobile" @search="search" :value.sync="queryForm.name" placeholder="请输入职位名称"></search>
      <div v-else class="select-wrapper">
        <el-input
          @clear="search"    
          @keyup.enter.native="search"
          placeholder="请输入职位名称"
          v-model="queryForm.name" class="keyword"
          clearable>
        </el-input>
      </div>
      <div class="split-vertical-line"></div>
      <search @search="search" :value.sync="queryForm.city" placeholder="请输入城市名称"></search>
    </div> -->
    <div class="filter-container clearfilx">
      <el-select style="margin-right: .3rem;" @change="cityChange" clearable v-model="queryForm.city" placeholder="请选择所属公司">
        <el-option style="font-size: .16rem;" v-for="item in cityList" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-select style="margin-right: .3rem;" @change='departmentChange' clearable v-model="queryForm.department" placeholder="请选择所属部门">
        <el-option style="font-size: .16rem;" v-for="item in departmentList" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <div class="tips">此处未显示的分公司或部门无招聘需求</div>
    </div>
    <div class="recruit-list-container">
      <transition mode="out-in" enter-active-class="fadeIn" leave-active-class="fadeOut">
        <transition-group :key="pageData[0]?pageData[0].id:'-1'" class="recruit-list" name="recruit-list" tag="div">
          <div v-for="(item) in pageData" :key="item.id" class="jop" :class="{expand:item.id===currentId}">
            <img v-if='item.top == 1' class="hot" src="../../assets/img/contactus/hot.png" alt="">
            <div class="job-profile clearfix">
              <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
                <div v-if="currentId == item.id" class="operate-btn" v-on:click="shrinkDetail()">
                  <img src="../../assets/img/contactus/recruit_shrink.png" />
                  <span>收起</span>
                </div>
                <div v-else class="operate-btn" v-on:click="getDetail(item)">
                  <img src="../../assets/img/contactus/recruit_open.png" />
                  <span>我要应聘</span>
                </div>
              </transition>
              <div class="jop-name">{{item.name}}</div>
              <div class="jop-desc">{{item.department}} | {{item.education}} | {{item.major}} | 所需人数：{{item.require_num}}</div>
              <!-- <div class="jop-location" v-show="currentId == item.id">
                <div>{{item.city}}</div>
                <div>{{item.recruit_time}}</div>
                <div>{{item.require_num}}</div>
              </div> -->
            </div>
            <transition enter-active-class=" fadeInUp">
              <div class="job-detail" v-show="currentId == item.id">
                <div v-html="detailHtml"></div>
              </div>
            </transition>

          </div>
        </transition-group>
      </transition>

      <pager :total="total" :page="page" :limit="limit" @current-change="handleCurrentChange"></pager>
    </div>

  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
// import search from '@/components/contactUs/search'
import Pager from '@/components/common/Pager.vue'
import { isMobile } from '@/helper/utils'
import {
  getRecruitList,
  getRecruitDetail,
  getCities,
  getDepartments
} from '@/api/contactUs.js'
export default {
  components: {
    ItemTitle,
    // search,
    Pager
  },
  data() {
    return {
      isMobile: isMobile(),
      pageData: [],
      detailHtml: '',
      showDetailData: false,
      isShow: false,
      show: 'show',
      hide: 'hide',
      currentId: '',
      queryForm: {
        name: '',
        city: '',
        department: ''
      },
      page: 1,
      limit: 10,
      total: 0,
      cityList:[],
      departmentList: []
    }
  },
  created() {
    this.getCityList()
    this.getData()
  },
  methods: {
    // 获取城市列表
    async getCityList () {
      const res = await getCities({})
      this.cityList = res
    },
    // 城市（公司）筛选
    cityChange (city) {
      this.queryForm.city = city
      this.queryForm.department = ''
      this.search()
      this.getDepartmentList(city)
    },
    // 获取部门列表
    async getDepartmentList (city) {
      const res = await getDepartments({ city: city })
      this.departmentList = res
    },
    // 部门筛选
    departmentChange () {
      this.search()
    },
    // 获取页面数据
    async getData() {
      const res = await getRecruitList({
        page: this.page,
        limit: this.limit,
        ...this.queryForm
      })
      this.pageData = res.records
      this.total = res.total;
      this.$emit('loading', false)
    },
    async getDetail(item) {
      const res = await getRecruitDetail({
        'id': item.id
      })
      this.currentId = item.id
      this.detailHtml = this.escape2Html(res.detail);
      this.detailHtml = this.handleEnter(this.detailHtml);
    },
    handleEnter(content) {
      var string = content;
      try {
        string = string.replace(/\r\n/g, "<br>")
        string = string.replace(/\n/g, "<br>");
      } catch (e) {
        alert(e.message);
      }
      return string;
    },
    escape2Html(str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t]
      })
    },
    shrinkDetail() {
      this.currentId = ''
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData()
    },
    // cityChange() {
    //   this.search()
    // },
    search() {
      this.page = 1;
      this.getData()
    }
  }
}
</script>
<style lang="scss">
.page-recruit {
  .select-wrapper {
    .keyword {
      height: 100%;
    }
    input {
      height: 100%!important;
      line-height: 0.48rem!important;
      border-radius: 0.24rem!important;
      border: 0 none;
      font-size: 0.16rem;
      padding: 0 .2rem;
    }
  }
  .el-select > .el-input {
    font-size: 0.16rem;
    height: 0.48rem !important;
    line-height: 0.48rem !important;
  }
  .el-input__inner {
    border: 0 none;
    font-size: 0.16rem;
    height: 0.48rem !important;
    line-height: 0.48rem;
    padding: 0 0.3rem;
  }
  .el-select-dropdown__item {
    padding: 0 0.3rem;
    font-size: 0.16rem !important;
    span {
      font-size: 0.16rem !important;
    }
  }
  .el-select-dropdown__item .el-input__inner {
    border: 0 none;
    font-size: 0.16rem;
    height: 0.48rem !important;
    line-height: 0.48rem;
    padding: 0 0.3rem;
  }
  .el-input__icon {
    line-height: 0.48rem;
    margin-right: 0.05rem;
  }
  .el-select {
    box-shadow: 0px 0px 0.3rem 0px rgba(0, 0, 0, 0.1);
    width: 2.72rem;
    box-sizing: border-box;
    border-radius: 0.24rem;
    overflow: hidden;
    font-size: 0.16rem !important;
  }
}
</style>
<style lang="scss" scoped>
.main-box .page-recruit {
  overflow: visible !important;
  max-width: pxToRem(1100);
}
.page-recruit {
  .filter-container {
    padding: pxToRem(70) 0 pxToRem(50) 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .tips {
      color: #cccccc;
      font-size: .16rem;
    }
  }
  .split-vertical-line {
    margin-left: pxToRem(40);
    margin-right: pxToRem(40);
  }
  .recruit-list-container {
    width: 100%;
    overflow: hidden;
  }
  ::v-deep .common-search {
    margin-left: pxToRem(420);
    width: auto;
  }
  .select-wrapper {
    position: relative;
    float: left;
    width: 3.4rem;
    height: 0.48rem;
    border-radius: 0.24rem;
    box-shadow: 0rem 0rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
    border-radius: 0.24rem;
    font-size: 0.16rem;
    color: #666666;
    select,
    input {
      width: 100%;
      height: 0.48rem;
      line-height: 0.48rem;
      padding: 0 pxToRem(20);
      border: none;
      background: none;
      outline: none;
      appearance: none;
    }
    &::after {
      //content: "";
      position: absolute;
      right: pxToRem(20);
      top: pxToRem(18);
      width: pxToRem(20);
      height: pxToRem(20);
      background: url("~@/assets/img/aboutus/more.png") no-repeat;
      background-size: auto 100%;
      transform: rotate(90deg);
    }
  }

  .recruit-list {
    font-size: 0.16rem;
    .jop {
      color: #fff;
      border-radius: 0.04rem;
      position: relative;
      overflow: hidden;
      margin-bottom: pxToRem(10);
      position: relative;
      .hot {
        position: absolute;
        left: 0;
        top: 0;
        width: .48rem;
        height: .48rem;
      }
      .job-profile {
        padding: pxToRem(28) pxToRem(30);
        background: #f0f4f9;
        min-height: pxToRem(110);
        color: #333;
      }
      .job-detail {
        padding: 0.39rem 0.31rem;
        color: #666666;
      }
      .jop-name {
        font-size: 0.24rem;
      }

      .jop-desc {
        margin-top: pxToRem(6);
        color: #666;
      }

      .jop-location {
        margin-top: pxToRem(24);
        div {
          display: inline-block;
          margin-right: 0.4rem;
        }
      }

      .operate-btn {
        margin-top: .08rem;
        float: right;
        width: 1.54rem;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.25rem;
        background-color: #ff9323;
        color: #fff;
        font-size: 0.16rem;
        cursor: pointer;
        text-align: center;
        img {
          width: 0.17rem;
          height: 0.18rem;
          margin-right: 0.08rem;
          vertical-align: middle;
        }
      }
      .job-detail {
        padding: 0.39rem 0.31rem;
        font-size: 0.16rem;
        color: #666666;
      }

      &.expand {
        .job-profile {
          background: url("~@/assets/img/contactus/recruit_item_bg.png")
            no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }
        .jop-desc {
          color: #fff;
        }
        .operate-btn {
          background: #fff;
          color: #ff9323;
          img {
            width: pxToRem(12);
            height: pxToRem(9);
          }
        }
      }
    }
  }
  .recruit-list {
    animation-duration: 0.4s;
    .jop {
      transition: all 1s;
    }
  }
}
@media only screen and (max-width: $moble_width) {
  
  .page-recruit {
    .filter-container {
      padding: .3rem 0 .3rem;
      .el-select {
        margin-bottom: .2rem;
        width: 100%!important;
      }
    }
    ::v-deep .common-search {
      margin-left: 0;
      float: left;
      margin-top: 20px;
    }
    .common-search {
      width: 100%;
    }
    .recruit-list {
      .jop {
        .job-profile {
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        }
        .jop-desc {
          white-space: nowrap;
          overflow: hidden;
          word-break: break-all;
          text-overflow: ellipsis;
        }
        .operate-btn {
          //margin-top: 0.1rem;
        }
      }
    }
  }
  .split-vertical-line {
    display: none;
  }
}
</style>
