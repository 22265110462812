<template>
  <div class="page-select" style="display: flex; justify-content: center; aligin-items: center;">
    <!-- <el-pagination v-if="isMobile" hide-on-single-page prev-text="上一页" :pager-count="pagerCount" next-text="下一页" layout="prev, pager, next" :current-page.sync="currentPage" :page-size.sync="pageSize" :total="total" @current-change="handleCurrentChange">
    </el-pagination> -->
    <el-pagination hide-on-single-page prev-text="上一页" :pager-count="pagerCount" next-text="下一页" layout="prev, pager, next" :current-page.sync="currentPage" :page-size.sync="pageSize" :total="total" @current-change="handleCurrentChange">
    </el-pagination>
    <div v-if="!isMobile && total > limit" class="jumper-box" style="margin-left: .2rem; margin-top: 0.8rem; font-size: .16rem; color: #666; display: flex; align-items: center;">
      <span>前往</span>
      <el-select @change="handleCurrentChange" class="page-select jumper" style="border-radius: 4px;margin-left: .1rem;margin-right: .1rem; height: 0.44rem;line-height: 0.4rem; width: .6rem;" v-model="page" placeholder="请选择">
        <el-option style="font-size: .16rem; width: .6rem; text-align: center; padding: 0;"
          v-for="item in Math.ceil(total / limit)"
          :key="item"
          :label="item"
          :value="item">
        </el-option>
      </el-select>
      <span>页</span>
    </div>
    
  </div>
</template>
<script>
import { isMobile } from '@/helper/utils'
export default {
  data() {
    return {
      isMobile: isMobile(),
      pagerCount: isMobile() ? 5 : 7
    }
  },
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  created() {
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('current-change', val)
    }
  }
}
</script>
<style lang="scss">
 .el-select-dropdown__item.selected, .el-select .el-input__inner:focus {
    color: #fe7a1c!important;
  }
.page-select {
  .el-select-dropdown__item {
    font-size: .16rem!important;
  }
  .el-input__inner {
    height: 0.44rem!important;
  }
  .el-select .el-input.is-focus .el-input__inner, .el-select .el-input__inner:focus  {
    border-color: #fe7a1c!important;
  }
  .el-select-dropdown__item {
    font-size: .16rem!important;
  }
  .el-select-dropdown__item.selected, .el-select .el-input__inner:focus {
    color: #fe7a1c!important;
  }
  .el-select .el-input .el-select__caret.is-reverse, .el-input__suffix {
    display: none!important;
  }
  .el-select .el-input__inner {
    padding:0!important;
    font-size: .16rem;
    text-align: center;
  }

}
.el-pagination {
  margin-top: 0.8rem;
  text-align: center;
  font-size: 0.15rem;
  color: #666;

  .el-pager li {
    display: inline-flex;
    height: 0.44rem;
    width: 0.44rem;
    border-radius: 0.22rem;
    background-color: #fff;
    color: #666;
    margin: 0rem 0.05rem;
    // display: inline-block;
    justify-content: center;
    align-items: center;
    line-height: 0.4rem;
    border: 0.01rem solid #d0d0d0;
    cursor: pointer;
    min-width: auto;
    font-size: 0.15rem;
    font-weight: normal;
    &.active {
      background-color: #fe7a1c;
      color: #fff;
      border: 0.01rem solid #fe7a1c;
    }
    &.active + li {
      border-left: 0.01rem solid #d0d0d0;
    }
  }

  .btn-next,
  .btn-prev {
    padding: 0;
    width: 1.1rem;
    height: 0.44rem;
    background: #ffffff;
    border: 0.01rem solid #d0d0d0;
    border-radius: 0.22rem;
    margin: 0rem 0.05rem;
    display: inline-block;
    line-height: 0.4rem;
    color: #666;
  }
  button,
  span:not([class*="suffix"]) {
    height: 0.44rem;
    line-height: 0.42rem;
    font-size: 0.15rem;
    &:hover {
      color: $orange;
    }
  }
}
@media only screen and (max-width: $moble_width) {
  
  .el-pagination {
    margin-left: -15px;
    margin-right: -15px;
    .btn-next,
    .btn-prev {
      width: 48px;
      line-height: normal;
      height: .35rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;    
      span {
        line-height: normal;
        height: auto;
        font-size: 12px;
      }
    }
    .el-pager li {
      width: .35rem;
      height: .35rem;
      line-height: .35rem;
    }
  }
}
@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2){
  .el-pagination {
    transform: scale(.85);
    margin-left: -30px;
  }
}
@media screen and (device-width: 360px) and (device-height: 640px) {
  .el-pagination {
    transform: scale(.95);
    margin-left: -15px;
  }
}
</style>